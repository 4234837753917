.details {
    max-width: none !important;
}

.stores {
    margin-top: 1.5em;
}

.stores img {
    margin-top: 0.5em;
    max-height: 50px;
}

.stores img:first-child {
    margin-right: 0.5em;
}
