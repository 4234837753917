.home-page__logo {
    height: 60px;
    width: 184px;
    text-align: left;
    display: inline-block;
    float: left;
}

.home-page__logo a {
    color: #000;
    font-size: 24px;
}

.home-page__toolbar {
    text-align: right;
    display: inline-block;
    float: right;
    padding-left: 16px;
    height: 60px;
    overflow: hidden;
}

.home-page__toolbar .ant-menu {
    display: inline-block;
}

.home-page__logout {
    display: inline;
    float: right;
    min-width: 80px;
    padding-right: 1em;
}

.home-page__logout a {
    color: #434656;
}

.home-page__main-menu {
    margin-top: 1.5em;
}

.home-page__header.ant-layout-header {
    font-size: 14px;
    line-height: 60px;
    padding: 0 16px 0 24px;
    height: 60px;
    background: #fff;
    text-align: center;
}

.home-page__header.ant-layout-header .ant-divider-vertical {
    background-color: transparent;
    margin: 0 16px;
}

.home-page__loading {
    font-size: 100px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
